import { TextField, TextFieldProps } from '@mui/material'
import InputMask from 'react-input-mask'
import { useFormContext, Controller } from 'react-hook-form'

interface CustomProps {
  name: string
  mask?: string | (string | RegExp)[]
}

type InputProps = TextFieldProps & CustomProps

export function Input(props: InputProps) {
  const { register, control } = useFormContext()

  if (props.mask) {
    return (
      <Controller
        name={props.name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputMask
            mask={props.mask || ''}
            value={value}
            disabled={false}
            onChange={onChange}
            maskChar=" "
          >
            {() => (
              <TextField
                id={props.name}
                {...props}
                defaultValue={props.defaultValue}
                label={props.label}
                variant={props.variant || 'outlined'}
                autoComplete="off"
              />
            )}
          </InputMask>
        )}
      />
    )
  }

  return (
    <TextField
      id={props.name}
      {...register(props.name)}
      {...props}
      defaultValue={props.defaultValue}
      label={props.label}
      variant={props.variant || 'outlined'}
    />
  )
}
